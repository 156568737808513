import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import { APP_TITLE } from '~/config/constants'
import history from '~/helpers/history'
import { setCurrentPage } from '~/store/reducers/ui/actions'
import { getUser } from '~/store/reducers/user/selectors'

const welcomePath = '/bem-vindo'
const loginPath = '/login'
const recoveryPath = '/recuperar-senha'
const resetPassPath = '/redefinir-senha'
const faqPath = '/perguntas-frequentes'
const legalPath = '/legal/privacidade'

const Page = ({ title, isPublic, ...props }) => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)

	useEffect(() => {
		let pageTitle = title

		if (typeof title === 'function') pageTitle = title()

		document.title = pageTitle ? `${pageTitle} - ${APP_TITLE}` : APP_TITLE

		if (!isPublic) {
			if (
				!user.aceiteTermoPrivacidade &&
				![
					welcomePath,
					loginPath,
					recoveryPath,
					resetPassPath,
					faqPath,
					legalPath,
				].includes(props.path.split('?')[0])
			) {
				history.replace(welcomePath)
				return
			}

			dispatch(setCurrentPage({ title: pageTitle, path: props.path }))
		}
	}, [title, dispatch, isPublic, props.path, user])

	return <Route {...props} />
}

export default Page
