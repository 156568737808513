/* eslint-disable */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import setFieldData from 'final-form-set-field-data'
import toast from 'react-hot-toast'
import { Button, CheckBoxField, Link } from '~/components'
import history from '~/helpers/history'
import { path as homePath } from '~/pages/homePage'
import { setUserPrivacyTermsAccepted } from '~/store/reducers/user/actions'
import { getUser } from '~/store/reducers/user/selectors'
import IconClose from '~/svgs/iconClose'

import {
	FormWrap,
	CloseButton,
	PrivacyTermsModal,
	StyledTermsText,
	TermsBody,
	TermsFooter,
	TermsHeader,
	StyledUseTermsText,
} from './styles'

const StartForm = () => {
	const dispatch = useDispatch()
	const user = useSelector(getUser)

	const [privacyTermsOpen, setPrivacyTermsOpen] = useState(false)
	const [useTermsOpen, setUseTermsOpen] = useState(false)
	
	const formApiRef = useRef(null)

	const handleSubmit = async (values) => {
		if (values.aceiteTermoPrivacidade) {
			try {
				await dispatch(setUserPrivacyTermsAccepted())
				history.push(homePath)
			} catch (err) {
				toast.error(err?.response?.data?.message ?? 'Erro no servidor')
			}
		}
	}

	const handleTermsAgreement = () => {
		setPrivacyTermsOpen(false)
		setUseTermsOpen(false)
		formApiRef.current?.change &&
			formApiRef.current.change('aceiteTermoPrivacidade', true)
	}

	return (
		<>
			<Form
				onSubmit={handleSubmit}
				initialValues={{ aceiteTermoPrivacidade: user?.aceiteTermoPrivacidade }}
				subscription={{ submitting: true, pristine: true, values: true }}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitting, form, values }) => {
					formApiRef.current = form

					return (
						<FormWrap onSubmit={handleSubmit}>
							<CheckBoxField
								name="aceiteTermoPrivacidade"
								label={
									<>
										Concordo com a{' '}
										<Link onClick={() => setPrivacyTermsOpen(true)}>
											Política de Privacidade
										</Link>
										{' '}e{' '}
										<Link onClick={() => setUseTermsOpen(true)}>
											Termos de Uso
										</Link>
										.
									</>
								}
							/>
							<Button
								type="submit"
								primary
								large
								inline
								disabled={!values?.aceiteTermoPrivacidade}
								submitting={submitting}
							>
								Começar
							</Button>
						</FormWrap>
					)
				}}
			</Form>

			<PrivacyTermsModal
				isOpen={privacyTermsOpen}
				onRequestClose={() => setPrivacyTermsOpen(false)}
			>
				<TermsHeader>
					Políticas de Privacidade
					<CloseButton onClick={() => setPrivacyTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledTermsText />
					<TermsFooter>
						<Button primary large onClick={handleTermsAgreement}>
							Concordar
						</Button>
					</TermsFooter>
				</TermsBody>
			</PrivacyTermsModal>

			<PrivacyTermsModal
				isOpen={useTermsOpen}
				onRequestClose={() => setUseTermsOpen(false)}
			>
				<TermsHeader>
					Termos e Condições do Portal de Boas Vindas
					<CloseButton onClick={() => setUseTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledUseTermsText />
					<TermsFooter>
						<Button primary large onClick={handleTermsAgreement}>
							Concordar
						</Button>
					</TermsFooter>
				</TermsBody>
			</PrivacyTermsModal>
		</>
	)
}

export default StartForm
