/* eslint-disable */
import React from 'react'

import { Wrapper } from './styles'
import { margin } from 'polished'

const TermosDeUso = ({ ...props }) => {
	return (
		<Wrapper {...props}>
			<p className="c1">
				<span className="c2">
					Este <b>Termos e Condições</b> estabelecem as diretrizes e regras aplicáveis ao uso da
					plataforma de recrutamento e seleção <b>“PORTAL DE BOAS VINDAS” (“Portal”)</b>,
					de propriedade da Americanas S.A – Em Recuperação Judicial. (<b>“AMERICANAS”</b>).
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					CASO VOCÊ NÃO CONCORDE COM QUAISQUER DISPOSIÇÕES DESTES TERMOS, NÃO UTILIZE O HOTSITE PLATAFORMA
					E NÃO SE CADASTRE, POIS, A UTILIZAÇÃO DO PORTAL E/OU CADASTRO REPRESENTAM SUA ACEITAÇÃO INTEGRAL
					E INCONDICIONAL A ESSES TERMOS E A POLÍTICA DE PRIVACIDADE.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					O conteúdo destes Termos deve ser analisado conjuntamente com a Política de Privacidade, disponíveis
					para acesso na área de Termos e Privacidade do <b>Portal</b>.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Para os fins destes Termos será considerado <b>“Candidato”</b> (ou <b>“Candidatos”</b>) toda
					pessoa natural (“pessoa física”) que utiliza o <b><b>Portal</b></b>.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					1. Objeto
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					1.1. Este Termo e Condições tem como objetivo coletar documentos e
					informações de Candidatos brasileiros e estrangeiros interessados em se candidatar
					a vagas sazonais e permanentes em Lojas, Centros de Distribuição (“CDs”) ou escritórios da <b>AMERICANAS</b>.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					2. Etapas do Processo Seletivo:
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					A) Pré-Cadastro:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.1. Após a aprovação do candidato nas plataformas de Recrutamento e Seleção, a próxima
					etapa consiste na validação de documentos dentro do PORTAL DE BOAS VINDAS. O candidato
					deverá fornecer algumas informações essenciais para dar continuidade ao processo seletivo.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.2. O <b><b>Candidato</b></b> receberá as informações de acesso ao <b>Portal</b>, por meio do e-mail fornecido
					no início do processo seletivo.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.2.1. O <b><b>Candidato</b></b> irá acessar o <b>Portal</b>, mediante o cadastrado de login e senha, que deverá
					ser tratado de forma confidencial, por ser pessoal e intransferível.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.3. O <b><b>Candidato</b></b> será exclusivamente responsável por todas as informações e documentos
					inseridos no hotsite através de seu login e senha.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.4. O <b><b>Candidato</b></b> precisará confirmar os seus dados pessoais básicos, necessários para
					a qualificação cadastral no E-social, sendo eles:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Nº de CPF.;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nome completo;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Data de Nascimento;</span><br />
				<span className="c2">&emsp;&emsp;(iv) E-mail;</span><br />
				<span className="c2">&emsp;&emsp;(v) Nº do PIS, para os Candidatos que tiverem o nº.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					a.5. O candidato terá a oportunidade de corrigir os dados antes de enviar o documento.
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					B) Cadastro
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					b.1. Caso aprovado na etapa de Pré-Cadastro, o <b><b>Candidato</b></b> avançará para a fase de Cadastro que
					consiste no envio das informações necessárias para a realização do processo admissional.
				</span>
			</p>

			<p className="c1">
				<span className="c2">
					b.2. Nessa etapa será necessário apresentar os seguintes dados e documentos:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Nome completo;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nome do pai;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Nome da mãe;</span><br />
				<span className="c2">&emsp;&emsp;(iv) Local de nascimento (país, estado, cidade);</span><br />
				<span className="c2">&emsp;&emsp;(v) Sexo;</span><br />
				<span className="c2">&emsp;&emsp;(vi) Cor ou raça;</span><br />
				<span className="c2">&emsp;&emsp;(vii) Estado civil;</span><br />
				<span className="c2">&emsp;&emsp;(viii) Arquivo digital da certidão de casamento, se houver;</span><br />
				<span className="c2">&emsp;&emsp;(ix) Número, UF de expedição e data de emissão, e arquivo digital do
					RG;</span><br />
				<span className="c2">&emsp;&emsp;(x) Número e foto do CPF;</span><br />
				<span className="c2">&emsp;&emsp;(xi) Informações da carteira de trabalho (se o documento é físico ou
					digital; número de registro, número de série; data de emissão, UF de emissão, arquivo digital das
					páginas que contêm a foto e a digital do candidato, página de qualificação civil, a última página
					da carteira de trabalho e a posterior, em branco);</span><br />
				<span className="c2">&emsp;&emsp;(xii) Informações da Carteira Nacional de habilitação (nº de registro;
					UF de emissão; data de validade; categoria), arquivo digital de frente e verso do documento, se
					houver;</span><br />
				<span className="c2">&emsp;&emsp;(xiii) Informações do título de eleitor (inscrição, zona, seção) e
					arquivo digital de frente e verso do documento;</span><br />
				<span className="c2">&emsp;&emsp;(xiv) Certificado de reservista (Número, data de dispensa, UF de
					emissão) e arquivo digital de frente e verso do documento.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.2.1. Caso o <b><b>Candidato</b></b> seja Pessoa Com Deficiência (“PcD”), deverá encaminhar:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Tipo de deficiência;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Descrição da Deficiência;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Arquivo digital do laudo médico.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.2.2. No caso de Candidatos estrangeiros, serão solicitados os seguintes dados pessoais:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Nome completo;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nome do pai;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Nome da mãe;</span><br />
				<span className="c2">&emsp;&emsp;(iv) Local de nascimento (país, estado, cidade);</span><br />
				<span className="c2">&emsp;&emsp;(v) Sexo;</span><br />
				<span className="c2">&emsp;&emsp;(vi) Cor ou raça;</span><br />
				<span className="c2">&emsp;&emsp;(vii) Estado civil;</span><br />
				<span className="c2">&emsp;&emsp;(viii) Arquivo digital da certidão de casamento;</span><br />
				<span className="c2">&emsp;&emsp;(ix) Dados do RNE (órgão emissor, UF, data de expedição) e arquivo
					digital do documento;</span><br />
				<span className="c2">&emsp;&emsp;(x) Dados de passaporte (nº, data de chegada ao Brasil, número do
					visto) e arquivo digital da página de identificação;</span><br />
				<span className="c2">&emsp;&emsp;(xi) Número, UF de expedição e data de emissão, e arquivo digital
					do RG;</span><br />
				<span className="c2">&emsp;&emsp;(xii) Número e foto do CPF (com a opção de informar que o nº
					CPF consta no RG);</span><br />
				<span className="c2">&emsp;&emsp;(xiii) Informações da Carteira Nacional de habilitação (nº de
					registro; UF de emissão; data de validade; categoria) e arquivo digital de frente e verso do
					documento;</span><br />
				<span className="c2">&emsp;&emsp;(xiv) Informações do título de eleitor (inscrição, zona, seção) e
					arquivo digital de frente e verso do documento;</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.3. Em relação aos dados de endereço e de contato, deverá fornecer as seguintes informações:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Endereço completo (país, CEP, estado, município, logradouro,
					bairro, nº) e arquivo digital do comprovante de residência;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nº de celular;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Nº de telefone secundário;</span><br />
				<span className="c2">&emsp;&emsp;(iv) E-mail principal;</span><br />
				<span className="c2">&emsp;&emsp;(v) E-mail secundário.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.4. O <b><b>Candidato</b></b> deverá apresentar informações sobre a sua formação, sendo eles:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Escolaridade;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nome da instituição;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Tipo da instituição;</span><br />
				<span className="c2">&emsp;&emsp;(iv) Formação;</span><br />
				<span className="c2">&emsp;&emsp;(v) País;</span><br />
				<span className="c2">&emsp;&emsp;(vi) Data de Início (mês/ano);</span><br />
				<span className="c2">&emsp;&emsp;(vii) Data de Conclusão (mês/ano);</span><br />
				<span className="c2">&emsp;&emsp;(viii) Arquivo do comprovante de escolaridade.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.5. O <b><b>Candidato</b></b> deverá apresentar ainda os seguintes dados:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Vínculo do Familiar;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Nome completo;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Data de nascimento;</span><br />
				<span className="c2">&emsp;&emsp;(iv) Nº do CPF;</span><br />
				<span className="c2">&emsp;&emsp;(v) País e estado de nascimento;</span><br />
				<span className="c2">&emsp;&emsp;(vi) Sexo;</span><br />
				<span className="c2">&emsp;&emsp;(vii) Se é dependente para fins de imposto de renda.</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					b.6. O <b><b>Candidato</b></b> pode, a qualquer momento, salvar o formulário e retomar o preenchimento
					posteriormente, bem como corrigir os dados fornecidos.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					3. Admissão:
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					A) Exame Admissional e Dados Bancários:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.1. Em caso de aprovação na Etapa de Cadastro, o <b><b>Candidato</b></b> receberá, via <b>Portal</b>, as instruções
					para a realização do exame admissional e para a abertura da conta bancária no Banco Bradesco.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.2. Após a conclusão do exame admissional e da abertura da conta, o candidato deverá anexar o ASO
					e as informações da conta bancária no <b>Portal</b>.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					4. Ações Complementares:
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					A) Foto e Informações para Crachá:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a.1. O <b><b>Candidato</b></b> precisará enviar uma foto e indicar o nome pelo qual deseja ser chamado no crachá
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					B) Opção de Vale Transporte:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					b.1. Nesta etapa, o <b><b>Candidato</b></b> deverá escolher se fará uso do vale-transporte e fornecer informações
					sobre o tipo, trajeto e valor do transporte
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					C) Tamanho de Uniforme:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					c.1. O <b><b>Candidato</b></b> também deverá escolher o tamanho do uniforme e, em seguida, realizar o pedido.
					O uniforme será disponibilizado apenas para cargos específicos.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					5. Responsabilidades:
				</span>
			</p>
			<p className="c9" style={{ marginBottom: '15px' }}>
				<span className="c3 c5" >
					5.1. São responsabilidades da <b>AMERICANAS</b>:
				</span>
			</p>

			<p className="c1">
				<span className="c2">
					b.5. O <b><b>Candidato</b></b> deverá apresentar ainda os seguintes dados:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Fornecer ao candidato todas as informações necessárias para a
					apresentação e inclusão dos dados e documentos exigidos em cada etapa do recrutamento;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Disponibilizar um canal de atendimento ao <b><b>Candidato</b></b> e prestar o
					suporte necessário para a execução do Serviço;</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					5.2. Além daquelas dispostas nos Termos e Condições Gerais, são responsabilidades do <b><b>Candidato</b></b>:
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Usar o hotsite dentro dos padrões de ética, educação e
					responsabilidade e, exclusivamente, nos termos aqui definidos;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Cumprir com todos os requisitos e obrigações legais vigentes
					aplicáveis à atividade exercida de forma voluntária e autônoma na plataforma;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Apresentar documentos autênticos e fornecer informações
					fidedignas e atualizadas no momento do cadastro do <b>Portal</b>;</span><br />
			</p>
			<p className="c1">
				<span className="c2">
					5.3. A <b>AMERICANAS</b> se reserva o direito de tomar as medidas cabíveis, nos termos da legislação
					brasileira em vigor, caso suspeite que o <b>Portal</b> tenha sido utilizado de forma fraudulenta, ilícita
					ou que haja qualquer violação a este Termo e Condições.
				</span>
				<br />
				<br />
				<span className="c2">&emsp;&emsp;(i) Usar o hotsite dentro dos padrões de ética, educação e r
					esponsabilidade e, exclusivamente, nos termos aqui definidos;</span><br />
				<span className="c2">&emsp;&emsp;(ii) Cumprir com todos os requisitos e obrigações legais vigentes
					aplicáveis à atividade exercida de forma voluntária e autônoma na plataforma;</span><br />
				<span className="c2">&emsp;&emsp;(iii) Apresentar documentos autênticos e fornecer informações
					fidedignas e atualizadas no momento do cadastro do <b>Portal</b>;</span><br />
			</p>
			<p className="c9">
				<span className="c6 c5">
					6. Compartilhamento de Informações:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					6.1. As Partes reconhecem que em virtude da natureza do Serviço, a transferência de dados pessoais é
					necessária para a execução do presente Termo de Uso e que serão tratadas de acordo com os termos do
					Aviso de Privacidade do <b>Portal</b> (“Aviso”), sem prejuízo de observância aos temos da Lei Geral de
					Proteção de dados e legislações correlatas.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					6.2. Por isso, ao participar das etapas de recrutamento e seleção, o <b><b>Candidato</b></b> está ciente que o
					tratamento dos seus dados pessoais ocorrerá de acordo com as finalidades previstas no Aviso de
					Privacidade. Caso o candidato não concorde com os termos do Aviso, deverá desistir da candidatura.
				</span>
			</p>

			<p className="c1">
				<span className="c2">
					6.3. Conforme previsto no Aviso de Privacidade, os dados pessoais tratados pela <b>AMERICANAS</b> serão
					armazenados de acordo com normas de prescrição do Direito Brasileiro. Findo o prazo prescricional,
					os dados pessoais ainda poderão ser tratados pela <b>AMERICANAS</b> nos casos descritos no artigo 16 da
					Lei Geral de Produção de Dados (LGPD).
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					7 - Propriedade Intelectual:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					7.1. Por meio do cadastro ao <b>Portal</b>, o <b><b>Candidato</b></b> reconhece e concorda que toda a Propriedade
					Intelectual utilizada, relacionada ou resultante do hotsite são de propriedade ou está devidamente
					licenciada à <b>AMERICANAS</b> e/ou às suas Afiliadas. “Propriedade Intelectual” significa, em uma
					lista não exaustiva: (a) marcas (registradas ou não), nomes comerciais, nomes de domínio e outros
					nomes que identificam a <b>AMERICANAS</b>, Afiliadas e seus serviços; (b) patentes, invenções patenteáveis,
					modelos de utilidade, aprimoramentos, know-how, tecnologias e programas de computador
					(incluindo código fonte e código objeto); (c) segredos comerciais e informações confidenciais;
					(d) direitos autorais, projetos, direitos de criação ou outros trabalhos,
					registros ou solicitações de registro de direitos autorais em qualquer jurisdição.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					8. Disposições Finais:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.1. As condições previstas neste Termos e Condições poderão ser alteradas a qualquer tempo
					pela <b>AMERICANAS</b>, visando seu aprimoramento e melhoria dos serviços prestados. Para cumprir
					com os deveres de informação e transparência, a <b>AMERICANAS</b> disponibilizará eventuais
					modificações através de e-mail do <b><b>Candidato</b></b> cadastrado e os Termos e Condições alterados
					entrarão em vigor 10 (dez) dias após a sua modificação.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.2. Todas as etapas do processo seletivo descritas neste documento serão avaliadas pela área
					de seleção interna da <b>AMERICANAS</b>.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.3. A <b>AMERICANAS</b> não se responsabiliza por quaisquer danos ou prejuízos decorrentes de
					falhas no hotsite, nos servidores da <b>AMERICANAS</b> ou na Internet, na medida em que não pode
					garantir a disponibilidade ininterrupta do <b>Portal</b> em casos de dificuldades técnicas,
					falhas relacionadas à internet ou outras circunstâncias alheias à sua vontade.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.4. A <b>AMERICANAS</b> não se responsabiliza por informações ou documentos encaminhados fora
					dos prazos indicados no <b>Portal</b>, cabendo ao <b><b>Candidato</b></b> cumprir com a exigências apresentadas e prazos.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.5. Caso seja necessária alguma correção dos dados incorretos ou arquivos ilegíveis,
					em qualquer etapa, é solicitado ao candidato a retificação.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.6. Na hipótese de o <b><b>Candidato</b></b> desejar redefinir sua senha, após fazer a solicitação
					no <b>Portal</b>, ele receberá o e-mail abaixo com um link que o direcionará para a página onde
					poderá criar uma nova senha.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.7. Caso o <b>Candidato</b> demore a o candidato demorar a concluir a próxima etapa, sua conta será
					bloqueada e ele será notificado por e-mail.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.8. Aplicam-se as leis brasileiras às disposições previstas nestes Termos e Condições.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.9. Qualquer controvérsia decorrente da interpretação sobre o <b>Portal</b> será resolvida de acordo
					com o princípio da boa-fé, elegendo as partes o Foro da Comarca da Capital do Rio de Janeiro
					como aquele competente para processar e julgar quaisquer disputas oriundas desta relação.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					8.10. Quaisquer dúvidas poderão ser feitas pelo e-mail
					&nbsp;<a href="mailto:atendimento.portal.boas.vindas@americanas.io" target='_blank'>
						atendimento.portal.boas.vindas@americanas.io
					</a>, com atendimento de segunda a sexta das (08h às 18h).
				</span>
			</p>














		</Wrapper>
	)
}

export default TermosDeUso
