/* eslint-disable */

import React from 'react'
import styled from 'styled-components'
// import LogoAmericanas from '../logos/logoAmericanas'
import Modal from '../modal'
import PhotoImg from './photoImg'
import americanasCracha from './assets/americanas_sa_vermelho_1.png'

function NameTagModal({ info, ...props }) {

	let name = info?.fullName
	let nomes
	try {
		if (name) {
			let nameSplit = name.split(" ")
			if (nameSplit.length > 1)
				nomes = nameSplit[0] + " " + nameSplit[nameSplit.length - 1]
			else
				nomes = name
		}
	} catch (ex) {
		console.log(ex)
		nomes = name
	}

	return (
		<WrapperModal isOpen={Boolean(info)} {...props}>
			<NameTag>
				<StyledPhotoImg file={info?.photoFile} />
				<img src={americanasCracha} style={{ zIndex: 0 }} />
				<div style={{ gridArea: 'names', zIndex: 10 }}>
					<TagName>{nomes}</TagName>
					{/* <FullName>{info?.fullName}</FullName> */}
				</div>
				{/* <div style={{ gridArea: 'logo', color: '#fff' }}>
					<LogoAmericanas size={1.9} />
				</div> */}
			</NameTag>
		</WrapperModal>
	)
}

const StyledPhotoImg = styled(PhotoImg)`
	grid-area: photo;
	width: 94px;
	height: 125px;
	margin: 0 auto;
	z-index: 10;

	border: 0;
	box-shadow: 0 0 4px 0px rgb(0, 0, 0, 0.4);

	& ~ * {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
`

const WrapperModal = styled(Modal)`
	--close-button-color: #550404;
	padding: 0;
	width: 250px;
	overflow: hidden;
	.modal-body {
		width: 100%;
		position: relative;
		height: 0;
		padding-top: 157%;
	}
`

const NameTag = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: grid;
	overflow: hidden;
	grid-template-rows: 24% 32% 1fr 20%;
	grid-template-areas:
		'_'
		'photo'
		'names'
		'logo';
	background-image: linear-gradient(
		to bottom,
		#ee0f0f 20%,
		#fff 20%,
		#fff 80%,
		#ee0f0f 80%
	);
	background-image: linear-gradient(
		to bottom,
		#ee0f0f 20%,
		#fff 20% 80%,
		#ee0f0f 80%
	);
`

const TagName = styled.div`
	text-align: center;
	width: min-content;
	text-transform: lowercase;
	font-size: 1.5rem;
	color: #FFF;
	font-weight: 600;
`
const FullName = styled.div`
	font-size: 0.75rem;
	font-weigth: bold;
	color: #FFF;
	margin-top: 4px;
`

export default NameTagModal
