import LoginPage, * as loginPageData from './loginPage'
import RecoveryPage, * as recoveryPageData from './recoveryPage'
import ResetPassPage, * as resetPassPageData from './resetPassPage'
import HomePage, * as homePageData from './homePage'
import WelcomePage, * as welcomePageData from './welcomePage'
import FaqPage, * as faqPageData from './faqPage'
import AboutPage, * as aboutPageData from './aboutPage'
import PreRegistrationPage, * as preRegistrationPageData from './preRegistrationPage'
import RegistrationPage, * as registrationPageData from './registrationPage'
import PersonalDataPage, * as personalDataPageData from './personalDataPage'
import AddressContactPage, * as addressContactPageData from './addressContactPage'
import EducationPage, * as educationPageData from './educationPage'
import FamilyPage, * as familyPageData from './familyPage'
import FamilyMemberPage, * as familyMemberPageData from './familyMemberPage'
import ReviewPage, * as reviewPageData from './reviewPage'
import ComplementaryPage, * as complementaryPageData from './complementaryPage'
import AsoPage, * as asoPageData from './asoPage'
import BankingInfoPage, * as bankingInfoPageData from './bankingInfoPage'
import PhotoPage, * as photoPageData from './photoPage'
import VtPage, * as vtPageData from './vtPage'
import UniformPage, * as uniformPageData from './uniformPage'
import PrivacyPage, * as privacyPageData from './privacyPage'
import DocReviewPage, * as docReviewPageData from './docReviewPage'
import LegalPage, * as legalPageData from './legalPage'

import ErrorPage, * as errorPageData from './errorPage'

const pages = [
	{
		component: LoginPage,
		...loginPageData,
	},
	{
		component: RecoveryPage,
		...recoveryPageData,
	},
	{
		component: ResetPassPage,
		...resetPassPageData,
	},
	{
		component: HomePage,
		...homePageData,
	},
	{
		component: WelcomePage,
		...welcomePageData,
	},
	{
		component: FaqPage,
		...faqPageData,
	},
	{
		component: AboutPage,
		...aboutPageData,
	},
	{
		component: PrivacyPage,
		...privacyPageData,
	},
	{
		component: PreRegistrationPage,
		...preRegistrationPageData,
	},
	{
		component: RegistrationPage,
		...registrationPageData,
	},
	{
		component: PersonalDataPage,
		...personalDataPageData,
	},
	{
		component: AddressContactPage,
		...addressContactPageData,
	},
	{
		component: EducationPage,
		...educationPageData,
	},
	{
		component: FamilyPage,
		...familyPageData,
	},
	{
		component: FamilyMemberPage,
		...familyMemberPageData,
	},
	{
		component: ReviewPage,
		...reviewPageData,
	},
	{
		component: ComplementaryPage,
		...complementaryPageData,
	},
	{
		component: AsoPage,
		...asoPageData,
	},
	{
		component: BankingInfoPage,
		...bankingInfoPageData,
	},
	{
		component: PhotoPage,
		...photoPageData,
	},
	{
		component: VtPage,
		...vtPageData,
	},
	{
		component: UniformPage,
		...uniformPageData,
	},
	{
		component: DocReviewPage,
		...docReviewPageData,
	},
	{
		component: LegalPage,
		...legalPageData,
	},

	// manter no fim (rota *)
	{
		component: ErrorPage,
		...errorPageData,
	},
]

export default process.env.NODE_ENV === 'development'
	? pages
	: pages.filter((p) => !p.isPublic)
