/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form } from 'react-final-form'
import toast from 'react-hot-toast'

import { Text, Title, Button, CheckBoxField, Link } from '~/components'
import api from '~/services/api'
import { logout } from '~/store/reducers/user/actions'
import history from '~/helpers/history'
import MainLayout from '~/layouts/mainLayout'

import { path as homePath } from '../homePage'
import IconClose from '~/svgs/iconClose'

import {
	AboutWrapper,
	ButtonContainer,
	ButtonConfirmRemove,
	CloseButton,
	FormBox,
	FormWrap,
	PrivacyModal,
	StyledTermsText,
	TermsBody,
	TermsHeader,
	TextBox,
} from './styles'
import { StyledUseTermsText } from '../welcomePage/startForm/styles'

export const title = 'Políticas de Privacidade'
export const path = '/privacidade'

const AboutPage = () => {
	const dispatch = useDispatch()
	// const user = useSelector(getUser)

	const [privacyTermsOpen, setPrivacyTermsOpen] = useState(false)
	const [useTermsOpen, setUseTermsOpen] = useState(false)

	const [privacyExcludeOpen, setPrivacyExcludeOpen] = useState(false)
	const [checkConfirm1, setCheckConfirm1] = useState(false)
	const [checkConfirm2, setCheckConfirm2] = useState(false)
	const [checkConfirm3, setCheckConfirm3] = useState(false)
	const [confirmationExclusion, setConfirmationExclusion] = useState(false)
	const formApiRef = useRef(null)

	const handleCheckAll = () => {
		setCheckConfirm1(!confirmationExclusion)
		setCheckConfirm2(!confirmationExclusion)
		setCheckConfirm3(!confirmationExclusion)
		setConfirmationExclusion(!confirmationExclusion)
	}

	const handleCheckConfirm1 = () => {
		setCheckConfirm1(!checkConfirm1)
	}

	const handleCheckConfirm2 = () => {
		setCheckConfirm2(!checkConfirm2)
	}

	const handleCheckConfirm3 = () => {
		setCheckConfirm3(!checkConfirm3)
	}

	useEffect(() => {
		if (checkConfirm1 && checkConfirm2 && checkConfirm3) {
			setConfirmationExclusion(true)
		} else {
			setConfirmationExclusion(false)
		}
	}, [checkConfirm1, checkConfirm2, checkConfirm3])

	const handleConfirmExclusion = async () => {
		console.log(confirmationExclusion)

		if (confirmationExclusion) {
			try {
				await api.user.delete()

				toast.success(`Conta excluída`)

				dispatch(logout())
			} catch (err) {
				console.error(err)
				toast.error('Erro ao excluir sua conta. Tente novamente')
			}
		}

		// if(values.aceiteTermoPrivacidade) {
		//   try {
		//     await dispatch(setUserPrivacyTermsAccepted())
		//     history.push(homePath)
		//   }
		//   catch(err) {
		//     toast.error(err?.response?.data?.message ?? "Erro no servidor")
		//   }
		// }
	}

	const handleSubmit = async () => {}

	return (
		<>
			<MainLayout onGoBack={() => history.push(homePath)}>
				<AboutWrapper>
					<Title size="1.8rem">Políticas de Privacidade</Title>
					<Text>
						<p>
							Você pode ler o Termo de Privacidade novamente{' '}
							<Link onClick={() => setPrivacyTermsOpen(true)}>
								clicando aqui
							</Link>
							.
						</p>
					</Text>

					<Title size="1.8rem">Termos e Condições</Title>
					<Text>
						<p>
							Você pode ler os Termos e Condições novamente{' '}
							<Link onClick={() => setUseTermsOpen(true)}>
								clicando aqui
							</Link>
							.
						</p>
					</Text>
					<Title size="1.8rem">Exclusão de dados</Title>
					<TextBox>
						<p>
							Você pode solicitar a exclusão dos seus dados do{' '}
							<b>Portal Boas Vindas</b> da <b>americanas sa</b> a qualquer
							momento, mas atenção: após essa ação você será totalmente
							desligado do processo seletivo que esta participando e a sua conta
							será extinta.
						</p>
						<ButtonContainer>
							<Button large primary onClick={() => setPrivacyExcludeOpen(true)}>
								Excluir meus dados
							</Button>
						</ButtonContainer>
					</TextBox>
				</AboutWrapper>
			</MainLayout>
			<PrivacyModal
				isOpen={privacyTermsOpen}
				onRequestClose={() => setPrivacyTermsOpen(false)}
			>
				<TermsHeader>
					Política de Privacidade e Proteção de Dados para Candidatos da
					Americanas
					<CloseButton onClick={() => setPrivacyTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledTermsText />
				</TermsBody>
			</PrivacyModal>
			<PrivacyModal
				isOpen={useTermsOpen}
				onRequestClose={() => setUseTermsOpen(false)}
			>
				<TermsHeader>
					Termos e Condições do Portal de Boas Vindas
					<CloseButton onClick={() => setUseTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledUseTermsText />
				</TermsBody>
			</PrivacyModal>
			<PrivacyModal
				isOpen={privacyExcludeOpen}
				onRequestClose={() => setPrivacyExcludeOpen(false)}
			>
				<TermsHeader>
					Exclusão de dados
					<CloseButton onClick={() => setPrivacyExcludeOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<Text>Ao excluir, estou ciente que:</Text>
					<FormBox>
						<Form
							onSubmit={handleSubmit}
							subscription={{ submitting: true, pristine: true, values: true }}
						>
							{/* {({ handleSubmit, submitting, form, values }) => { */}
							{({ handleSubmit, form }) => {
								formApiRef.current = form

								return (
									<FormWrap onSubmit={handleSubmit}>
										<div className="itemChecks">
											<CheckBoxField
												name="confirm1"
												checked={checkConfirm1}
												label="Estou excluindo meus dados"
												onClick={handleCheckConfirm1}
											/>
										</div>
										<div className="itemChecks">
											<CheckBoxField
												name="confirm2"
												checked={checkConfirm2}
												label="Me desligo do processo seletivo para essa vaga"
												onClick={handleCheckConfirm2}
											/>
										</div>
										<div className="itemChecks">
											<CheckBoxField
												name="confirm3"
												checked={checkConfirm3}
												label="Deleto todo o meu cadastro do Portal Boas Vindas"
												onClick={handleCheckConfirm3}
											/>
										</div>

										<div className="lastCheck">
											<CheckBoxField
												name="confirmAll"
												checked={confirmationExclusion}
												label="Concordo com todas as afirmativas acima"
												onClick={handleCheckAll}
											/>
										</div>
										<ButtonConfirmRemove>
											<Button
												large
												primary
												disabled={!confirmationExclusion}
												onClick={handleConfirmExclusion}
											>
												Excluir
											</Button>
										</ButtonConfirmRemove>
									</FormWrap>
								)
							}}
						</Form>
					</FormBox>
				</TermsBody>
			</PrivacyModal>
		</>
	)
}

export default AboutPage
