/* eslint-disable */
import React from 'react'

import { Wrapper } from './styles'
import { borderColor } from 'polished'

const TermsText = ({ ...props }) => {
	return (
		<Wrapper {...props}>
			<p className="c1">
				<span className="c2">
					Olá, Candidato(a)! É um prazo tê-lo conosco
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A Americanas tem o compromisso com a transparência, a privacidade e a segurança dos dados
					pessoais dos candidatos que participam de nosso processo seletivo.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Como respeitamos a sua privacidade, criamos este Aviso de Privacidade (“Aviso”), para que você
					entenda como as suas informações pessoais serão processadas através do nosso Portal (“Portal”).
					Leia nosso Aviso com atenção, pois ao prosseguir com a candidatura através no nosso Portal, você
					concordará com a coleta das suas informações de acordo com o nosso Aviso.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					1. Definições Importantes.
				</span>
			</p>

			<p className="c1">
				<span className="c2">
					Para que você esteja familiarizado com os principais conceitos que abordamos neste Aviso,
					preparamos um guia com os principais termos usados.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Aqui explicamos, de forma clara e direta, o significado de algumas palavras e expressões
					importantes que aparecem no texto. Outras definições também podem ser consultadas na própria &nbsp;
					<a href='https://www.planalto.gov.br/ccivil_03/_ato2015-2018/2018/lei/l13709.htm' target='_black'>
						Lei Geral de Proteção de Dados (artigo 5º).
					</a>
				</span>
			</p>

			<p className="c1">
				<ul>
					<li>
						<span className="c2">
							<b>Candidato:</b> são todas as pessoas físicas que tenham se inscrito ou tenham sido recrutadas para
							participar de algum processo de seleção realizados pela Americanas e às empresas da marca
							(se você chegou até aqui, considere-se nosso candidato).
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Controlador:</b> pessoa física ou jurídica (empresa, organização ou entidade pública) que toma
							decisões sobre o tratamento dos dados pessoais. Em outras palavras, é quem decide quais
							dados serão coletados, por que motivos (finalidade), e de que forma esses dados serão usados.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Dados Pessoais:</b> Informações que identificam ou possam identificá-lo, como seu nome, CPF,
							endereço, e-mail, telefone, histórico profissional etc.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Dados Pessoal Sensível:</b> informações que se refiram a origem racial ou étnica, convicção
							religiosa, opinião política, filiação a sindicato ou a organização de caráter religioso,
							filosófico ou político, dado referente à saúde ou à vida sexual, dado genético ou
							biométrico, quando vinculado a uma pessoa natural.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Tratamento de Dados Pessoais:</b> Qualquer ação/processamento realizado com seus dados
							pessoais, incluindo a coleta, armazenamento, uso, compartilhamento e exclusão.
							As informações que você insere no Portal são tratadas pela Americanas.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Titular dos dados:</b> pessoa a quem os dados pessoais se referem — neste caso, você,
							o Candidato.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Finalidade:</b> motivo ou propósito para o qual seus dados serão coletados e processados
							pela Americanas
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Portal (“Plataforma”):</b> ferramenta digital onde você faz o envio dos seus documentos e
							informações para participar de uma das etapas de nosso processo seletivo.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>Processo Seletivo:</b> etapas que avaliam se você atende aos requisitos para ocupar uma das
							nossas oportunidades de trabalho.
						</span>
					</li>
					<li>
						<span className="c2">
							<b>LGPD (Lei Geral de Proteção de Dados/ “LGPD”):</b> lei brasileira que protege a privacidade
							dos seus dados e estabelece direitos e deveres sobre como esses dados devem ser tratados.
						</span>
					</li>
				</ul>
			</p>
			<p className="c9">
				<span className="c6 c5">
					2. Quem é o controlador do Portal?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A Americanas S.A., CNPJ 00.776.574/0006-60, é a controladora dos dados pessoais. Portanto,
					o tratamento dos dados pessoais que serão realizados no Portal (de acordo com as finalidades
					previstas neste Aviso), será realizado tanto pelas marcas que compõe a Companhia, como
					às empresas relacionadas ao grupo.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					3. A quem este Aviso de Privacidade se aplica?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Este Aviso de Privacidade se aplica apenas aos candidatos que foram convidados ao cadastramento
					do Portal (etapa prévia à contratação do Candidato) e seus beneficiários (como dependentes).
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Caso você queira informações a respeito de como os dados pessoais são tratados nas Plataformas
					de candidatura às vagas ofertadas pela Companhia, deve verificar as Políticas/Avisos de
					Privacidade da Plataformas que você se cadastrou. Estas Plataformas são nossas parceiras de
					negócio e nos auxiliam nos processos seletivos, mas são consideradas controladoras e possuem
					processamentos próprios dos dados pessoais, nos quais não temos participação.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Além disso, o documento não contempla o uso dos nossos serviços e o processamento de dados
					relacionados aos nossos associados. Caso queira ter acesso à outras Políticas/Avisos de
					Privacidade da Companhia, entre em contato conosco ou consulte os canais próprios de contato.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					4. Como e quais dados são coletados pelo Portal?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A Americanas poderá coletar os dados pessoais através das seguintes formas:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					a. Por meio de empresas parceiras: Inclui empresas que nos auxiliam nos processos de
					recrutamento e seleção, como instituições de ensino, consultorias de headhunting,
					entre outras, para que seja possível a autenticação do usuário no Portal.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					b. Diretamente com o candidato: Informações obtidas quando o candidato realiza etapas
					do processo seletivo no Portal.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					c. A partir de fontes públicas: Inclui informações de órgãos e autarquias ligadas ao
					Ministério do Trabalho, Receita Federal, registros profissionais e outras bases públicas.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Os dados coletados estão relacionados às seguintes categorias:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					<table id="tabelaTerms" style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
						<thead>
							<tr>
								<td><b>Categorias de Dados</b></td>
								<td><b>Especificação</b></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Dados de Identificação</td>
								<td>Nome completo, nº de identificação (como RG, RNE CPF, CNH, PIS), certidões
									(nascimento, casamento, união estável), número do PIS, título de eleitor,
									certificado de reservista, declaração de nascido vivo (DNV), local de nascimento,
									estado civil, nome do pai, nome da mãe.</td>
							</tr>
							<tr>
								<td>Dados de Contato</td>
								<td>E-mail pessoal, endereço residencial/ correspondência, número de telefone pessoal.</td>
							</tr>
							<tr>
								<td>Dados Sensíveis</td>
								<td>Informações que revelam sua origem racial ou étnica, convicção religiosa,
									opinião política, filiação à sindicato ou à organização de caráter religioso,
									filosófico ou político, dado referente à saúde, à vida ou orientação sexual,
									dado genético ou biométrico, como informação a respeito de PcD, informações de
									atestados médicos, e qualquer outros que estejam nesta categoria e que sejam
									necessários para o recrutamento ou seleção, exame admissional etc.</td>
							</tr>
							<tr>
								<td>Dados Identificadores/Comprobatórios</td>
								<td>Identificação nacional/passaporte, status de cidadania, status de permissão de
									residência e trabalho, CPF ou outro número de identificação de
									contribuinte/governo. Caso seja necessário para o desempenho da função,
									números de inscrição profissional perante as instituições competentes
									(tais como OAB e CREA) e outros documentos comprobatórios de requisitos para a f
									unção, assinatura física e digital etc., Cartão Nacional de Saúde (CNS).</td>
							</tr>
							<tr>
								<td>Dados Educacionais</td>
								<td>Informações acadêmicas, profissionais e antecedentes: qualificações acadêmica e
									profissional, currículo, histórico escolar, observações de entrevistas,
									referências relacionadas a candidatura (se registradas), resultados de testes
									(se aplicáveis), informações sobre seus empregos anteriores ou atuais, ocupação
									de cargo público, histórico de posição creditícia, antecedentes criminais
									(a depender da natureza do cargo) ou outras informações reveladas durante
									avaliações de antecedentes.
								</td>
							</tr>
							<tr>
								<td>Dados Financeiro</td>
								<td>Informações de conta bancária.</td>
							</tr>
							<tr>
								<td>Dados Audiovisuais/Biométricos</td>
								<td>Imagem para confecção de crachás, acesso às instalações e sistemas da Companhia
									e seu monitoramento. Tamanho de roupa para confecção de camisetas/uniforme etc.
								</td>
							</tr>
							<tr>
								<td>Dados de Dependentes</td>
								<td>Informações do cônjuge/parceiro e dependentes (incluindo menores): respectivos
									nomes e sobrenomes, datas de nascimento e detalhes de contato.
								</td>
							</tr>
							<tr>
								<td>Dados relacionados ao Uso do Portal</td>
								<td>Dados de navegação, informações de acesso e uso da Plataforma, endereço de IP.
								</td>
							</tr>
						</tbody>
					</table>
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					5. Para qual finalidade estas informações são coletadas?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Os dados coletados são utilizados exclusivamente para antecipação do processamento de eventual
					admissão dos candidatos. A finalidade do tratamento inclui:
				</span>
			</p>
			<p className="c1">
				<ul>
					<li>
						<span className="c2">
							Gerenciar o processo de recrutamento e seleção às vagas da Americanas;
						</span>
					</li>
					<li>
						<span className="c2">
							Para compor banco de dados que poderá ser consultado em outras oportunidades de vagas na
							Americanas ou em empresas do mesmo grupo;
						</span>
					</li>
					<li>
						<span className="c2">
							Para avaliar se o perfil profissional e as competências técnicas do candidato
							potencialmente o qualificam para ocupar a vaga disponível na Americanas;
						</span>
					</li>
					<li>
						<span className="c2">
							Para que seja possível entrar em contato com o candidato ou responder eventuais dúvidas
							ou requerimentos;
						</span>
					</li>
					<li>
						<span className="c2">
							Para viabilizar ou instruir processos seletivos e estatísticas relacionadas a processos
							seletivos na Americanas e em demais empresas da Americanas s.a.;
						</span>
					</li>
					<li>
						<span className="c2">
							Para conduzir o processo de contratação do candidato como associado na Americanas e
							demais empresas da Americanas s.a.;
						</span>
					</li>
					<li>
						<span className="c2">
							Realizar consultas e verificações legais, se aplicável;
						</span>
					</li>
					<li>
						<span className="c2">
							Cumprir normas legais ou regulatórias.
						</span>
					</li>
				</ul>
			</p>
			<p className="c9">
				<span className="c6 c5">
					5.1. E para os dados pessoais sensíveis, qual a finalidade da coleta?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					O tratamento de dados pessoais sensíveis pela Americanas, será realizado apenas para que seja possível:
				</span>
			</p>
			<p className="c1">
				<ul>
					<li>
						<span className="c2">
							Cumprir normas legais ou regulatórias;
						</span>
					</li>
					<li>
						<span className="c2">
							Garantir condições de trabalho adequadas;
						</span>
					</li>
					<li>
						<span className="c2">
							Garantia de segurança aos ativos da Companhia e aos próprios Associados;
						</span>
					</li>
					<li>
						<span className="c2">
							Para realizar ações afirmativas de diversidade e/ou inclusão.
						</span>
					</li>
				</ul>
			</p>

			<p className="c1">
				<span className="c2">
					Também é possível que seja realizado o tratamento de Dados Pessoais sensíveis através do
					seu consentimento expresso. Caso este consentimento seja necessário, a Americanas S.A.
					adotará medidas para garantir que as informações a respeito do tratamento de dados pessoais
					e as consequências da ausência de consentimento sejam transparentes, inclusive quando
					relacionadas a tratamento de dados pessoais tratados por terceiros.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					6. A Americanas compartilha seus dados pessoais?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Os dados pessoais poderão ser compartilhados com terceiros/parceiros de negócio e consultores
					previamente autorizados, para apoio às atividades relacionadas ao Portal, bem como para
					prover a infraestrutura operacional necessária a disponibilidade da Plataforma (como
					servidores em nuvem, por exemplo), outros sistemas de gestão de processos de recursos seletivos,
					realização de exames admissionais etc, avaliação de PcD, etc. Garantimos que qualquer parceiro com
					quem compartilhamos dados observe práticas de proteção de dados.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Além disso, os dados pessoais podem ser compartilhados para cumprir medidas judiciais ou
					requerimento de Autoridades competentes, ou caso seja necessário para realizar defesa em
					processos judiciais ou administrativos.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					7. Os meus dados pessoais são mantidos no Brasil ou há a transferência internacional?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Caso seja necessária a transferência dos seus dados pessoais a servidores de armazenamento de
					dados pessoais fora do território brasileiro, a Americanas adotará medidas contratuais e
					legais para garantir que os dados pessoais sejam tratados de acordo com as diretrizes e
					medidas de segurança declarados neste Aviso.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					8. Quais as medidas de segurança adotadas pela Americanas para garantir a segurança das minhas
					informações?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A Americanas se compromete em manter os Dados Pessoais relacionados a este Aviso em segurança,
					adotando medidas administrativas, técnicas, de pessoal e físicas adequadas para protegê-los
					contra acessos não autorizados e situações acidentais ou ilícitas que venham a causar a
					destruição, perda, alteração, ou qualquer forma de tratamento inadequado ou ilícito,
					como a proteção física e lógica dos ativos, comunicações criptografadas, gestão sobre
					os acessos e políticas internas de conformidade
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					9. Por quanto tempo a Americanas armazena meus dados pessoais?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Seus dados pessoais serão mantidos enquanto forem necessários para o processo de admissão
					ou pelo prazo de retenção previsto em lei. Após o término do processo e vencimento de
					eventuais prazos legais, os dados serão excluídos ou anonimizados.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					10. Quais são meus direitos relacionados ao processamento de dados pessoais?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					De acordo com a LGPD, você tem o direito de:
				</span>
			</p>
			<p className="c1">
				<ul>
					<li>
						<span className="c2">
							Confirmar a existência do tratamento de dados.
						</span>
					</li>
					<li>
						<span className="c2">
							Acessar seus dados pessoais.
						</span>
					</li>
					<li>
						<span className="c2">
							Corrigir dados incompletos, inexatos ou desatualizados.
						</span>
					</li>
					<li>
						<span className="c2">
							Solicitar a anonimização, bloqueio ou eliminação de dados desnecessários.
						</span>
					</li>
					<li>
						<span className="c2">
							Solicitar a portabilidade de dados, se aplicável.
						</span>
					</li>
					<li>
						<span className="c2">
							Verificar com quais terceiros seus dados pessoais são compartilhados.
						</span>
					</li>
					<li>
						<span className="c2">
							Revogar o consentimento, quando o tratamento for baseado nele.
						</span>
					</li>
					<li>
						<span className="c2">
							Revisão de decisões realizadas única e exclusivamente de forma automatizada.
						</span>
					</li>
				</ul>
			</p>
			<p className="c1">
				<span className="c2">
					Você pode exercer os direitos listados neste Aviso de Privacidade através do Canal do
					Encarregado na Proteção de Dados, que está informado abaixo.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					11. Como entrar em contato com o encarregado da proteção de dados da Americanas?
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					O encarregado da proteção de dados é o responsável escolhido pela Americanas para atuar como
					canal de comunicação entre o controlador, os titulares dos dados e a Autoridade Nacional de
					Proteção de Dados (ANPD).
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Você pode entrar em contato com o nosso Encarregado na Proteção de Dados para requerer seus
					direitos enquanto titular e esclarecer dúvidas, pelo e-mail
					<a href="mailto:privacidade.canal@americanas.io" target='_blank'>
						privacidade.canal@americanas.io
					</a>.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					12. Vigência, atualização e revisão:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Este Aviso de Privacidade passa a viger na data de sua publicação, e pode ser revisada a qualquer
					momento. O texto revisado será publicado no mesmo canal, com a data em que foi modificado.
					Se houver alguma mudança substancial, você receberá uma notificação através dos seus canais
					de contato.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					13. Legislação e foro competente:
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Este Aviso será regido, interpretado e executado de acordo com as Leis da República Federativa
					do Brasil, especialmente a Lei nº 13.709/2018 (“Lei Geral de Proteção de Dados”),
					independentemente das Leis de outros estados ou países, sendo competente o foro de
					domicílio do Candidato para dirimir qualquer dúvida decorrente deste documento.
				</span>
			</p>
			<p className="c9">
				<span className="c6 c5">
					14. Última atualização e versões anteriores
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					A última atualização desta Aviso de Privacidade foi realizada em 29/10/2024.
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					Última atualização e versões anteriores
				</span>
			</p>
			<p className="c1">
				<span className="c2">
					<table id="tabelaTerms" style={{ width: '100%' }} cellPadding={0} cellSpacing={0}>
						<thead>
							<tr>
								<td><b>Data</b></td>
								<td><b>Motivo</b></td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>29/10/2024</td>
								<td>Atualização</td>
							</tr>
						</tbody>
					</table>
				</span>
			</p>
		</Wrapper>
	)
}

export default TermsText
