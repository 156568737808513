/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form'
import setFieldData from 'final-form-set-field-data'
import createDecorator from 'final-form-calculate'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInCalendarYears, parseISO } from 'date-fns'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'

import MainLayout from '~/layouts/mainLayout'
import {
	AttachmentField,
	Button,
	CheckBoxField,
	CpfField,
	DateTextField,
	FieldWrapper,
	FormError,
	FormWrapper,
	FormBody,
	FormFooter,
	FormTip,
	SelectField,
	Text,
	TextField,
	FieldGroup,
	FieldValuesSpy,
	FormRow,
	RadioField,
	RadioFieldGroup,
	FormIsntComplete,
	Loading,
	FormInfo,
	Link,
} from '~/components'
import usePBVForm from '~/hooks/usePBVForm'
import history from '~/helpers/history'
import {
	UFS,
	CITIES_BY_UF,
	COUNTRIES,
	SEXES,
	ETNIAS,
	ESTADOS_CIVIS,
	RNE_CLASSIFICACAO,
	PCD_TYPES,
	PCD_LIST_BY_TYPE,
} from '~/config/constants'
import { getUser } from '~/store/reducers/user/selectors'
import { setUser } from '~/store/reducers/user/actions'
import {
	dateAfter,
	dateBefore,
	minLength,
	nameMustBeComplete,
} from '~/helpers/validators'
// import { path as registrationPath } from '../registrationPage'
import { formatGenericDoc, formatOnlyNumbers } from '~/helpers/formatters'
import { breakpointMatch } from '~/helpers/breakpoints'
import { getViewPortSize } from '~/store/reducers/ui/selectors'

import ErrorPage from '../errorPage'

export const title = 'Dados pessoais'
export const path = '/dados-cadastrais/dados-pessoais'

// decorator para apagar o campo conjugeBrasileiro ao selecionar opção != "Casado" no estado civil
const estadoCivilHandler = createDecorator({
	field: 'estadoCivil',
	updates: {
		conjugeBrasileiro: (estadoCivilValue, values) =>
			estadoCivilValue !== 'casado' ? undefined : values.conjugeBrasileiro,
		certidaoCasamento: (estadoCivilValue, values) =>
			estadoCivilValue !== 'casado' ? {} : values.certidaoCasamento,
	},
})
// decorator para apagar o campo nomeMae ao selecionar nada a informar
const nomeMaeHandler = createDecorator({
	field: 'naoExisteNomeMae',
	updates: {
		nomeMae: (naoExisteNomeMaeValue, values) =>
			naoExisteNomeMaeValue ? undefined : values.nomeMae,
	},
})
// decorator para apagar o campo nomePai ao selecionar nada a informar
const nomePaiHandler = createDecorator({
	field: 'naoExisteNomePai',
	updates: {
		nomePai: (naoExisteNomePaiValue, values) =>
			naoExisteNomePaiValue ? undefined : values.nomePai,
	},
})
// decorator para apagar o campo municipio ao selecionar estado
const ufHandler = (form) =>
	createDecorator({
		field: 'uf',
		updates: () => {
			if (!form.getFieldState('uf')?.modified) return {}

			return {
				municipio: undefined,
			}
		},
	})(form)

const pcdTypeHandler = (form) =>
	createDecorator({
		field: 'pcd.tipo',
		updates: () => {
			if (!form.getFieldState('pcd.tipo')?.modified) return {}

			return {
				'pcd.descricao': '',
			}
		},
	})(form)

const attachmentFields = [
	'certidaoCasamento.idAnexo',
	'rne.idAnexo',
	'pdc.idAnexo',
	'passaporte.idAnexo',
	'rg.idAnexoFrente',
	'rg.idAnexoVerso',
	'cpf.idAnexo',
	'ctps.idAnexoPaginaFoto',
	'ctps.idAnexoPaginaQualificacao',
	'ctps.idAnexoPaginaUltimoContrato',
	'ctps.idAnexoPaginaEmBranco',
	'ctps.idAnexoPaginaUltimoContratoDigital',
	'cnh.idAnexoFrente',
	'cnh.idAnexoVerso',
	'tituloEleitor.idAnexoFrente',
	'tituloEleitor.idAnexoVerso',
	'certificadoReservista.idAnexoFrente',
	'certificadoReservista.idAnexoVerso',
]

const dateFields = [
	'rne.dataExpedicao',
	'passaporte.dataChegadaBrasil',
	'rg.dataEmissao',
	'cnh.dataValidade',
	'certificadoReservista.dataDispensa',
	'ctps.dataEmissao',
]

const defaultValues = {
	pais: 'BR', // valor default para país
	certidaoCasamento: {},
	rne: {},
	passaporte: {},
	rg: {},
	cpf: {},
	ctps: {},
	cnh: {},
	pcd: {},
	tituloEleitor: {},
	certificadoReservista: {},
}

const PersonalDataPage = () => {
	// pega o parâmetro "backTo" da url, se houver
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const [city, setCity] = useState('')
	const [ctpsType, setCtpsType] = useState(null)
	const [ctpsPrevJob, setCtpsPrevJob] = useState(null)

	const vp = useSelector(getViewPortSize)
	const { isDesktop } = breakpointMatch(vp)

	const user = useSelector(getUser)
	const userAgeInCalendarYears = differenceInCalendarYears(
		new Date(),
		parseISO(user?.dataNascimento),
	)

	const dispatch = useDispatch()

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm('dadosPessoais', {
			attachmentFields, // array de campos não é definido diretamente aqui pois perderia a referência e faria componente re-renderizar o tempo todo
			dateFields,
			defaultValues,
			afterSubmit: (values) => {
				// bota informações no estado geral para uso em regras de campos de outros formulários
				dispatch(
					setUser({
						sexo: values.sexo,
						estadoCivil: values.estadoCivil,
					}),
				)
			},
		})

	useEffect(() => {
		if (
			initialValues !== undefined &&
			initialValues.ctps !== undefined &&
			initialValues.ctps.flagDigital !== undefined
		) {
			// setCtpsType(() =>
			// 	initialValues.ctps?.flagDigital === true ? 'digital' : 'fisica',
			// )
			setCtpsType(undefined)
		}

		if (
			initialValues !== undefined &&
			initialValues.ctps !== undefined &&
			initialValues.ctps.flagEmpregoAnterior !== undefined
		) {
			setCtpsPrevJob(() =>
				initialValues.ctps?.flagEmpregoAnterior === true ? 'sim' : 'nao',
			)
		}
	}, [initialValues])

	// const handleGoBack = () => history.push(backTo || path)
	const handleGoBack = () => history.goBack()

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				setCity(values.municipio)
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	const isCtpsPrevJobCheckedYes = ctpsPrevJob
		? ctpsPrevJob === 'sim'
			? { checked: true }
			: { checked: false }
		: ''
	const isCtpsPrevJobCheckedNo = ctpsPrevJob
		? ctpsPrevJob === 'nao'
			? { checked: true }
			: { checked: false }
		: ''

	const isCtpsTypeCheckedFisica = ctpsType
		? ctpsType === 'fisica'
			? { checked: true }
			: { checked: false }
		: ''
	const isCtpsTypeCheckedDigital = ctpsType
		? ctpsType === 'digital'
			? { checked: true }
			: { checked: false }
		: ''

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)

	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
				}}
				decorators={[
					estadoCivilHandler,
					nomeMaeHandler,
					nomePaiHandler,
					ufHandler,
					pcdTypeHandler,
				]}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitError, submitting, form }) => (
					<FormWrapper onSubmit={handleSubmit}>
						<FormBody>
							<Text dark>
								{editingDisabled ? (
									<FormInfo>
										Você já enviou seus dados pessoais e neste momento do
										processo eles não podem ser alterados
									</FormInfo>
								) : (
									<p>
										Preencha seus dados com muita atenção e não deixe de anexar
										a foto do documento oficial que comprove os dados
										preenchidos.
									</p>
								)}
							</Text>

							<FieldWrapper>
								<TextField name="nome" label="Nome completo" disabled />
								{/* <CheckBoxField name="existeNomeSocial" label="Incluir nome social" /> */}
							</FieldWrapper>

							{/* <FieldValuesSpy name="existeNomeSocial">
                {value => (
                  <FieldWrapper shouldRender={value}>
                    <TextField name="nomeSocial" label="Nome Social" />
                  </FieldWrapper>
                )}
              </FieldValuesSpy> */}

							{/* NOME MÃE */}
							<FieldWrapper>
								<FieldValuesSpy name="naoExisteNomeMae">
									{(naoExisteNomeMae) => (
										<TextField
											name="nomeMae"
											label="Nome da mãe"
											validators={[nameMustBeComplete, minLength(6)]}
											disabled={naoExisteNomeMae || editingDisabled}
										/>
									)}
								</FieldValuesSpy>
								<CheckBoxField
									name="naoExisteNomeMae"
									label="Nada a informar"
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							{/* NOME PAI */}
							<FieldWrapper>
								<FieldValuesSpy name="naoExisteNomePai">
									{(naoExisteNomePai) => (
										<TextField
											name="nomePai"
											label="Nome do pai"
											validators={[nameMustBeComplete, minLength(6)]}
											disabled={naoExisteNomePai || editingDisabled}
										/>
									)}
								</FieldValuesSpy>
								<CheckBoxField
									name="naoExisteNomePai"
									label="Nada a informar"
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							{/* PAÍS */}
							<FieldWrapper>
								<SelectField
									name="pais"
									label="País de nascimento"
									showSearchByDefault
									options={COUNTRIES}
									disabled={editingDisabled}
								/>
							</FieldWrapper>

							<FieldValuesSpy names={['pais', 'uf', 'municipio']}>
								{({ pais, uf, municipio }) => (
									<FormRow
										template={[0.24, 'auto']}
										disableTemplate={!(pais === 'BR' || !pais)}
									>
										{/* UF */}
										<FieldWrapper>
											{pais === 'BR' || !pais ? (
												<SelectField
													name="uf"
													label="UF"
													value={!UFS.includes(uf) ? form.change('uf', '') : uf}
													options={UFS}
													disabled={!pais || editingDisabled}
												/>
											) : (
												<TextField
													name="uf"
													label="Estado/Distrito/Província"
												/>
											)}
										</FieldWrapper>

										{/* MUNICÍPIO */}
										<FieldWrapper>
											{pais === 'BR' || !pais ? (
												<SelectField
													name="municipio"
													label="Município"
													showSearchByDefault
													options={CITIES_BY_UF[uf]}
													disabled={
														!pais || !uf || !UFS.includes(uf) || editingDisabled
													}
													value={municipio || city}
												/>
											) : (
												<TextField
													name="municipio"
													label="Cidade/Município"
													value={municipio || city}
												/>
											)}
										</FieldWrapper>
									</FormRow>
								)}
							</FieldValuesSpy>

							<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
								{/* SEXO */}
								<FieldWrapper>
									<SelectField
										name="sexo"
										label="Sexo"
										options={SEXES}
										disabled={editingDisabled}
									/>
								</FieldWrapper>

								{/* COR OU RAÇA */}
								<FieldWrapper>
									<SelectField
										name="cor"
										label="Cor ou raça"
										options={ETNIAS}
										disabled={editingDisabled}
									/>
								</FieldWrapper>
							</FormRow>

							{/* PCD */}
							<FieldValuesSpy names={['pcd', 'isPcd']}>
								{({ pcd, isPcd }) =>
									isPcd && (
										<FieldWrapper>
											<FieldGroup name="pcd" label="Pessoa com Deficiência">
												<FieldWrapper>
													<SelectField
														name="pcd.tipo"
														label="Tipo de Deficiência"
														options={PCD_TYPES}
														disabled={editingDisabled}
													/>
												</FieldWrapper>
												{PCD_LIST_BY_TYPE[pcd.tipo] && (
													<FieldWrapper>
														<SelectField
															name="pcd.descricao"
															label="Descrição da Deficiência"
															options={PCD_LIST_BY_TYPE[pcd.tipo]}
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												)}

												<FieldWrapper>
													<AttachmentField
														name="pcd.idAnexo"
														type="laudo_pcd"
														label="Laudo Médico"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FieldGroup>
										</FieldWrapper>
									)
								}
							</FieldValuesSpy>

							{/* ESTADO CIVIL */}
							<FieldWrapper>
								<SelectField
									name="estadoCivil"
									label="Estado Civil"
									options={ESTADOS_CIVIS}
									disabled={editingDisabled}
								/>
								<FieldValuesSpy name="estadoCivil">
									{(estadoCivil) =>
										!estadoCivil ? (
											<FormTip>
												A união estável não é reconhecida legalmente como estado
												civil.
											</FormTip>
										) : null
									}
								</FieldValuesSpy>
							</FieldWrapper>

							{/* CERTIDÃO CASAMENTO */}
							<FieldValuesSpy name="estadoCivil">
								{(estadoCivil) => (
									<FieldWrapper shouldRender={estadoCivil === 'casado'}>
										<FieldGroup
											name="certidaoCasamento"
											label="Certidão de casamento"
										>
											<FieldWrapper>
												<AttachmentField
													name="certidaoCasamento.idAnexo"
													type="certidao_casamento"
													label="Foto da Certidão"
													disabled={editingDisabled}
												/>
											</FieldWrapper>
										</FieldGroup>
									</FieldWrapper>
								)}
							</FieldValuesSpy>

							<FieldValuesSpy name="pais">
								{(pais) => (
									<>
										{/* RNE */}
										<FieldWrapper shouldRender={pais !== 'BR'}>
											<FieldGroup name="rne" label="RNE" mainField="rne.numero">
												<FieldWrapper>
													<SelectField
														name="rne.classificacao"
														label="Classificação"
														options={RNE_CLASSIFICACAO}
														disabled={editingDisabled}
													/>
												</FieldWrapper>
												<FormRow
													template="1fr 1fr"
													disableTemplate={!isDesktop}
												>
													<FieldWrapper>
														<TextField
															name="rne.numero"
															label="RNE"
															maxLength="20"
															formatter={formatGenericDoc}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<TextField
															name="rne.orgaoEmissor"
															label="Orgão emissor"
															maxLength="20"
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<SelectField
															name="rne.uf"
															label="UF"
															options={UFS}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<DateTextField
															name="rne.dataExpedicao"
															label="Data de expedição"
															validators={[dateBefore()]}
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												</FormRow>

												<FieldWrapper>
													<AttachmentField
														name="rne.idAnexo"
														type="rne"
														label="Foto do RNE"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FieldGroup>
										</FieldWrapper>

										{/* PASSAPORTE */}
										<FieldWrapper shouldRender={pais !== 'BR'}>
											<FieldGroup name="passaporte" label="Passaporte">
												<FormRow
													template="1fr 1fr"
													disableTemplate={!isDesktop}
												>
													<FieldWrapper>
														<TextField
															name="passaporte.numero"
															label="Número"
															maxLength="10"
															formatter={formatGenericDoc}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<DateTextField
															name="passaporte.dataChegadaBrasil"
															label="Data de chegada ao Brasil"
															validators={[dateBefore()]}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<TextField
															name="passaporte.numeroSerieVisto"
															label="Número do visto"
															maxLength="20"
															formatter={formatGenericDoc}
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												</FormRow>

												<FieldWrapper>
													<AttachmentField
														name="passaporte.idAnexo"
														type="passaporte"
														label="Página de Identificação"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FieldGroup>
										</FieldWrapper>
									</>
								)}
							</FieldValuesSpy>

							{/* RG */}
							<FieldWrapper>
								<FieldGroup name="rg" label="RG">
									<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
										<FieldWrapper>
											<TextField
												name="rg.numero"
												label="Número"
												maxLength="15"
												formatter={formatGenericDoc}
												disabled={editingDisabled}
											/>
										</FieldWrapper>

										<FieldWrapper>
											<TextField
												name="rg.orgao"
												label="Órgão"
												maxLength="20"
												disabled={editingDisabled}
											/>
										</FieldWrapper>

										<FieldWrapper>
											<SelectField
												name="rg.uf"
												label="UF de emissão"
												options={UFS}
												disabled={editingDisabled}
											/>
										</FieldWrapper>

										<FieldWrapper>
											<DateTextField
												name="rg.dataEmissao"
												label="Data de expedição"
												validators={[dateBefore()]}
												disabled={editingDisabled}
											/>
										</FieldWrapper>
									</FormRow>

									<FieldWrapper>
										<AttachmentField
											name="rg.idAnexoFrente"
											type="rg_frente"
											label="Frente do RG"
											disabled={editingDisabled}
										/>
									</FieldWrapper>

									<FieldWrapper>
										<AttachmentField
											name="rg.idAnexoVerso"
											type="rg_verso"
											label="Verso do RG"
											disabled={editingDisabled}
										/>
									</FieldWrapper>
								</FieldGroup>
							</FieldWrapper>

							{/* CPF */}
							<FieldWrapper>
								<FieldGroup
									name="cpf"
									label="CPF"
									mainField={['cpf.idAnexo', 'cpf.constaNoRG']}
								>
									<FieldWrapper>
										<CpfField name="cpf.numero" label="Número" disabled />
									</FieldWrapper>

									<FieldWrapper>
										<CheckBoxField
											name="cpf.constaNoRG"
											label="O número do CPF já consta no RG"
											disabled={editingDisabled}
										/>
									</FieldWrapper>

									<FieldValuesSpy name="cpf.constaNoRG">
										{(constaNoRG) => (
											<FieldWrapper shouldRender={!constaNoRG}>
												<AttachmentField
													name="cpf.idAnexo"
													type="cpf"
													label="Foto do CPF"
													disabled={editingDisabled}
												/>
											</FieldWrapper>
										)}
									</FieldValuesSpy>
								</FieldGroup>
							</FieldWrapper>

							{/* CTPS */}
							<FieldWrapper>
								<FieldGroup
									name="ctps"
									label="Carteira de Trabalho"
									mainField="ctps.numero"
								>
									<FieldWrapper>
										<RadioFieldGroup
											inline
											label="Sua carteira de trabalho é Física ou Digital?"
											helperText="Se tiver apenas no aplicativo, marque a opção Digital"
										>
											<RadioField
												name="ctps.flagDigital"
												onClick={() => setCtpsType('fisica')}
												{...isCtpsTypeCheckedFisica}
												value={false}
												label="Física"
												isBoolean
												disabled={editingDisabled}
											/>
											<RadioField
												name="ctps.flagDigital"
												onClick={() => setCtpsType('digital')}
												{...isCtpsTypeCheckedDigital}
												value
												label="Digital"
												isBoolean
												disabled={editingDisabled}
											/>
										</RadioFieldGroup>
									</FieldWrapper>

									<FieldValuesSpy name="ctps">
										{(ctps) =>
											ctps?.flagDigital === false ? (
												// carteira física
												<>
													<FormRow
														template="1fr 1fr"
														disableTemplate={!isDesktop}
													>
														<FieldWrapper>
															<TextField
																name="ctps.numero"
																label="Número"
																maxLength="20"
																formatter={formatOnlyNumbers}
																disabled={editingDisabled}
															/>
														</FieldWrapper>

														<FieldWrapper>
															<TextField
																name="ctps.numeroSerie"
																label="Número de série"
																maxLength="6"
																formatter={formatOnlyNumbers}
																disabled={editingDisabled}
															/>
														</FieldWrapper>

														<FieldWrapper>
															<DateTextField
																name="ctps.dataEmissao"
																label="Data de emissão"
																validators={[dateBefore()]}
																disabled={editingDisabled}
															/>
														</FieldWrapper>

														<FieldWrapper>
															<SelectField
																name="ctps.uf"
																label="UF de emissão"
																options={UFS}
																disabled={editingDisabled}
															/>
														</FieldWrapper>
													</FormRow>

													<FieldWrapper>
														<AttachmentField
															name="ctps.idAnexoPaginaFoto"
															type="ctps_foto"
															label="Página de foto"
															helperText="A página que possui a foto e a impressão digital"
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<AttachmentField
															name="ctps.idAnexoPaginaQualificacao"
															type="ctps_info"
															label="Página de qualificação civil"
															helperText="A página que possui as informações pessoais"
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<AttachmentField
															name="ctps.idAnexoPaginaUltimoContrato"
															type="ctps_ultimo_contrato"
															label="Última página de contrato"
															helperText="Última página que possui contrato de trabalho, com a devida baixa"
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<AttachmentField
															name="ctps.idAnexoPaginaEmBranco"
															type="ctps_pagina_branco"
															label="Página em branco após contrato"
															helperText="Página necessária para provar que não tem registros após o último contrato"
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												</>
											) : ctps?.flagDigital === true ? (
												// carteira digital
												<>
													<FormRow
														template="1fr 1fr"
														disableTemplate={!isDesktop}
													>
														<FieldWrapper>
															<DateTextField
																name="ctps.dataEmissao"
																label="Data de emissão"
																validators={[dateBefore()]}
																disabled={editingDisabled}
															/>
														</FieldWrapper>

														<FieldWrapper>
															<SelectField
																name="ctps.uf"
																label="UF de emissão"
																options={UFS}
																disabled={editingDisabled}
															/>
														</FieldWrapper>
													</FormRow>
													<FieldWrapper>
														<RadioFieldGroup
															inline
															label="Possui emprego anterior?"
														>
															<RadioField
																name="ctps.flagEmpregoAnterior"
																onClick={() => setCtpsPrevJob('sim')}
																{...isCtpsPrevJobCheckedYes}
																value
																label="Sim"
																isBoolean
																disabled={editingDisabled}
															/>
															<RadioField
																name="ctps.flagEmpregoAnterior"
																onClick={() => setCtpsPrevJob('nao')}
																{...isCtpsPrevJobCheckedNo}
																value={false}
																label="Não"
																isBoolean
																disabled={editingDisabled}
															/>
														</RadioFieldGroup>
													</FieldWrapper>
													<FieldWrapper
														shouldRender={ctps?.flagEmpregoAnterior}
													>
														<AttachmentField
															name="ctps.idAnexoPaginaUltimoContratoDigital"
															type="ctps_ultimo_contrato_digital"
															label="Último contrato de trabalho"
															helperText="Captura de tela do último contrato de trabalho com a devida baixa"
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												</>
											) : undefined
										}
									</FieldValuesSpy>
								</FieldGroup>
							</FieldWrapper>

							{/* CARTEIRA HABILITAÇÃO */}
							{userAgeInCalendarYears >= 16 && (
								<>
									<FieldWrapper>
										<FieldGroup
											name="cnh"
											label="Carteira Nacional de Habilitação"
											helperText="Opcional"
										>
											<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
												<FieldWrapper>
													<TextField
														name="cnh.numero"
														label="Número de Registro"
														maxLength="20"
														formatter={formatGenericDoc}
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<SelectField
														name="cnh.uf"
														label="UF de emissão"
														options={UFS}
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<DateTextField
														name="cnh.dataValidade"
														label="Data de validade"
														validators={[dateAfter()]}
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<TextField
														name="cnh.categoria"
														label="Categoria"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FormRow>

											<FieldWrapper>
												<AttachmentField
													name="cnh.idAnexoFrente"
													type="cnh_frente"
													label="Frente da CNH"
													disabled={editingDisabled}
												/>
											</FieldWrapper>

											<FieldWrapper>
												<AttachmentField
													name="cnh.idAnexoVerso"
													type="cnh_verso"
													label="Verso da CNH"
													disabled={editingDisabled}
												/>
											</FieldWrapper>
											<FieldWrapper>
												<Link
													onClick={() => {
														form.change('cnh.numero', '')
														form.change('cnh.uf', '')
														form.change('cnh.dataValidade', '')
														form.change('cnh.categoria', '')
														form.change('cnh.idAnexoFrente', '')
														form.change('cnh.idAnexoVerso', '')
													}}
												>
													Apagar informações
												</Link>
											</FieldWrapper>
										</FieldGroup>
									</FieldWrapper>

									{/* TÍTULO DE ELEITOR */}
									<FieldWrapper>
										<FieldGroup
											name="tituloEleitor"
											label="Título de eleitor"
											helperText="Obrigatório para maiores de 18 anos"
										>
											<FormRow template="1fr 1fr" disableTemplate={!isDesktop}>
												<FieldWrapper>
													<TextField
														name="tituloEleitor.inscricao"
														label="Inscrição"
														maxLength="20"
														formatter={formatOnlyNumbers}
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<TextField
														name="tituloEleitor.zona"
														label="Zona"
														maxLength="10"
														formatter={formatGenericDoc}
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<TextField
														name="tituloEleitor.secao"
														label="Seção"
														maxLength="10"
														formatter={formatGenericDoc}
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FormRow>

											<FieldWrapper>
												<AttachmentField
													name="tituloEleitor.idAnexoFrente"
													type="titulo_eleitor_frente"
													label="Frente do Título de eleitor"
													disabled={editingDisabled}
												/>
											</FieldWrapper>

											<FieldWrapper>
												<AttachmentField
													name="tituloEleitor.idAnexoVerso"
													type="titulo_eleitor_verso"
													label="Verso do Título de eleitor"
													disabled={editingDisabled}
												/>
											</FieldWrapper>
										</FieldGroup>
									</FieldWrapper>
								</>
							)}

							{/* CERTIFICADO DE RESERVISTA */}
							<FieldValuesSpy names={['pais', 'sexo']}>
								{({ pais, sexo }) =>
									pais === 'BR' &&
									sexo === 'masculino' &&
									userAgeInCalendarYears >= 19 &&
									userAgeInCalendarYears <= 45 && (
										<FieldWrapper>
											<FieldGroup
												name="certificadoReservista"
												label="Certificado de Reservista"
												helperText="Obrigatório para homens entre 18 e 45 anos"
											>
												<FormRow
													template="1fr 1fr"
													disableTemplate={!isDesktop}
												>
													<FieldWrapper>
														<TextField
															name="certificadoReservista.numeroCertificado"
															label="Número"
															maxLength="20"
															formatter={formatGenericDoc}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<DateTextField
															name="certificadoReservista.dataDispensa"
															label="Data de dispensa"
															validators={[dateBefore()]}
															disabled={editingDisabled}
														/>
													</FieldWrapper>

													<FieldWrapper>
														<SelectField
															name="certificadoReservista.uf"
															label="UF de emissão"
															options={UFS}
															disabled={editingDisabled}
														/>
													</FieldWrapper>
												</FormRow>

												<FieldWrapper>
													<AttachmentField
														name="certificadoReservista.idAnexoFrente"
														type="certificado_reservista_frente"
														label="Frente do Certificado de Reservista"
														disabled={editingDisabled}
													/>
												</FieldWrapper>

												<FieldWrapper>
													<AttachmentField
														name="certificadoReservista.idAnexoVerso"
														type="certificado_reservista_verso"
														label="Verso do Certificado de Reservista"
														disabled={editingDisabled}
													/>
												</FieldWrapper>
											</FieldGroup>
										</FieldWrapper>
									)
								}
							</FieldValuesSpy>
						</FormBody>

						<FormFooter>
							{submitError && <FormError error={submitError} />}

							<Button type="submit" primary large submitting={submitting}>
								{editingDisabled ? (
									'Voltar'
								) : (
									<>
										Salvar
										<FormIsntComplete> e continuar depois</FormIsntComplete>
									</>
								)}
							</Button>
						</FormFooter>
					</FormWrapper>
				)}
			</Form>
		</MainLayout>
	)
}

export default PersonalDataPage
