/* eslint-disable */
import React, { useState } from 'react'

import Link from '~/components/link'
import { CloseButton, PrivacyModal, StyledTermsText, TermsBody, TermsHeader } from './styles'
import IconClose from '~/svgs/iconClose'
import { StyledUseTermsText } from '../welcomePage/startForm/styles'
import IconArrow from '~/svgs/iconArrow'

export const title = 'Políticas de Privacidade'
export const path = '/legal/privacidade'
export const isPublic = true

const LegalPage = () => {
	const [privacyTermsOpen, setPrivacyTermsOpen] = useState(false)
	const [useTermsOpen, setUseTermsOpen] = useState(false)

	return (
		<div style={{ padding: "20px" }}>
			<Link onClick={() => window.location = "/login"}>
				<IconArrow dir='left'></IconArrow>Voltar para página de Login
			</Link>
			<br />
			<br />
			<p>
				Você pode ler a Política de Privacidade e Proteção de Dados para Candidatos da Americanas{' '}
				<Link onClick={() => setPrivacyTermsOpen(true)}>
					clicando aqui
				</Link>
				<br />
				<br />
				{' '}e os Termos e Condições do Portal de Boas Vindas{' '}
				<Link onClick={() => setUseTermsOpen(true)}>
					clicando aqui
				</Link>
				.
			</p>

			<PrivacyModal isOpen={privacyTermsOpen} onRequestClose={() => setPrivacyTermsOpen(false)} >
				<TermsHeader>
					Política de Privacidade e Proteção de Dados para Candidatos da
					Americanas
					<CloseButton onClick={() => setPrivacyTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledTermsText />
				</TermsBody>
			</PrivacyModal>

			<PrivacyModal
				isOpen={useTermsOpen}
				onRequestClose={() => setUseTermsOpen(false)}
			>
				<TermsHeader>
					Termos e Condições do Portal de Boas Vindas
					<CloseButton onClick={() => setUseTermsOpen(false)}>
						<IconClose />
					</CloseButton>
				</TermsHeader>
				<TermsBody>
					<StyledUseTermsText />
				</TermsBody>
			</PrivacyModal>
		</ div>
	)
}

export default LegalPage
