/* eslint-disable no-shadow */
/* eslint-disable indent */
import React, { useRef, useState } from 'react'
import setFieldData from 'final-form-set-field-data'
import { Form } from 'react-final-form'
// import queryString from 'query-string'
// import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import toast from 'react-hot-toast'

import {
	Button,
	FormBody,
	FormFooter,
	FormWrapper,
	Loading,
	Text,
	FieldWrapper,
	TextInput,
	TextField,
	FormTip,
	FormError,
	Modal,
	Title,
	FieldSection,
	FormInfo,
} from '~/components'
import history from '~/helpers/history'
import usePBVForm from '~/hooks/usePBVForm'
import MainLayout from '~/layouts/mainLayout'
// import { path as complementaryPath } from '../complementaryPage'
import { getUser } from '~/store/reducers/user/selectors'
import Camera from '~/components/camera'
import ImageEditor from '~/components/imageEditor'
import IconCamera from '~/svgs/iconCamera'
import IconAttachment from '~/svgs/iconAttachment'
import { delay } from '~/helpers/delay'
import api from '~/services/api'
import PhotoImg from '../../components/nameTag/photoImg'
import NameTagModal from '~/components/nameTag/nameTagModal'
import ErrorPage from '../errorPage'
import FormWarning from '~/components/formWarning'
import { STATUS_PHOTO } from '~/config/constants'
// import { mustString } from '~/helpers/validators'

import { HiddenInput, PhotoField, PhotoSavingLoader } from './styles'

export const title = 'Foto para o crachá'
export const path = '/foto-cracha'

const attachmentFields = ['idAnexo']

const PhotoPage = () => {
	// const { search } = useLocation()
	// const { backTo } = queryString.parse(search)

	const { nome: username } = useSelector(getUser)

	const { loading, error, initialValues, submitForm, editingDisabled } =
		usePBVForm(`fotoCracha`, {
			attachmentFields,
		})

	// const handleGoBack = () => history.push(backTo || complementaryPath)
	const handleGoBack = () => history.goBack()

	// ref onde será guardada api do form para acesso nos handlers do modal de foto
	const mainFormApiRef = useRef(null)

	const handleSubmit = async (values) => {
		try {
			if (!editingDisabled) {
				await submitForm(values)
			} else {
				handleGoBack()
			}
		} catch (err) {
			console.log(err)
		}
	}

	// modal para inserir foto
	const [modalOpen, setModalOpen] = useState(false)
	const [cameraOpen, setCameraOpen] = useState(false)
	const [photoEditScreen, setPhotoEditScreen] = useState(null) // armazena arquivo/blob, não true/false
	const [isSaving, setIsSaving] = useState(false)
	const cameraRef = useRef(null)
	const editorRef = useRef(null)

	const handlePhotoCapture = async () => {
		try {
			const blob = await cameraRef.current.capture()

			if (blob) {
				setPhotoEditScreen(
					new File([blob], 'foto-cracha', {
						type: 'image/jpeg',
						lastModified: Date.now(),
					}),
				)
				setCameraOpen(false)
			}
		} catch (err) {
			toast.error('Ocorreu um erro ao capturar. Tente novamente')
		}
	}

	const handlePhotoSave = async () => {
		try {
			const blob = await editorRef.current.save()

			if (blob) {
				setIsSaving(true)

				const file = new File([blob], 'foto-cracha', {
					type: 'image/jpeg',
					lastModified: Date.now(),
				})
				const formData = new FormData()
				formData.append('image', file)

				// enviar foto
				const { data } = await api.upload('foto_cracha').post({
					values: formData,
				})

				mainFormApiRef.current.change('idAnexo', {
					uuid: data?.id,
					file,
				})

				setIsSaving(false)

				setModalOpen(false)
				setPhotoEditScreen(null)
			}
		} catch (err) {
			console.log(err)
			setIsSaving(false)
			toast.error('Ocorreu um erro ao salvar. Tente novamente')
		}
	}

	// crachá
	const [nameTagInfo, setNameTagInfo] = useState(null)

	const openNameTag = (tagName, fullName, photoFile) => {
		setNameTagInfo({
			tagName,
			fullName: tagName,
			photoFile,
		})
	}
	const closeNameTag = () => setNameTagInfo(null)

	if (loading)
		return (
			<MainLayout hasSideMenu onGoBack={handleGoBack}>
				<Loading />
			</MainLayout>
		)
	if (error) return <ErrorPage hasSideMenu onGoBack={handleGoBack} />

	return (
		<MainLayout hasSideMenu onGoBack={handleGoBack}>
			<Form
				onSubmit={handleSubmit}
				initialValues={initialValues}
				subscription={{
					submitting: true,
					submitSucceeded: true,
					pristine: true,
					submitError: true,
					values: true,
				}}
				mutators={{ setFieldData }}
			>
				{({ handleSubmit, submitError, submitting, values, form }) => {
					mainFormApiRef.current = form

					return (
						<FormWrapper onSubmit={handleSubmit}>
							<FormBody>
								<Text dark>
									{editingDisabled ? (
										<FormInfo>
											Neste momento do processo sua foto não pode ser alterada
										</FormInfo>
									) : (
										<p>
											A sua foto será usada no seu cadastro e em seu crachá.
											Aqui você pode escolher o nome que aparecerá no seu
											crachá.
										</p>
									)}
								</Text>

								<FieldWrapper>
									<TextInput label="Nome completo" value={username} disabled />
								</FieldWrapper>

								<FieldWrapper>
									<TextField
										name="nomeDestaque"
										label="Nome em destaque"
										maxLength={20}
										// validators={[mustString]}
										disabled={editingDisabled}
									/>
									{!editingDisabled && (
										<FormTip>
											Lembre-se, bom senso é importante aqui, você será
											conhecido pelos seus clientes, colegas e gestores pelo
											nome que escolher.
										</FormTip>
									)}
								</FieldWrapper>

								{values.idAnexo?.uuid ? (
									// foto inserida
									<FieldSection
										label="Sua foto"
										labelLine
										style={{ marginBottom: 'var(--spacing)' }}
									>
										{values.statusDocumento === 'reprovado' &&
											Number(values.motivoReprovado) > 2 && (
												<FormWarning highlight center border>
													<Text>
														<span style={{ color: '#E92100' }}>
															{STATUS_PHOTO[values.motivoReprovado]}
														</span>
														<span>
															{' '}
															- Verifique abaixo e envie uma nova foto
														</span>
													</Text>
												</FormWarning>
											)}

										<PhotoField>
											<PhotoImg file={values.idAnexo?.file} />
											<div className="btns">
												{!editingDisabled && (
													<Button
														onClick={() => setModalOpen(true)}
														large
														Icon={IconCamera}
													>
														Anexar outra foto
													</Button>
												)}
												<Button
													onClick={() =>
														openNameTag(
															values.nomeDestaque,
															username,
															values.idAnexo?.file,
														)
													}
													large
												>
													Ver crachá
												</Button>
											</div>
										</PhotoField>
									</FieldSection>
								) : (
									!editingDisabled && (
										// foto não inserida
										<PhotoField>
											<FieldWrapper>
												<Button
													onClick={() => setModalOpen(true)}
													large
													Icon={IconCamera}
												>
													Anexar foto
												</Button>
											</FieldWrapper>
										</PhotoField>
									)
								)}
							</FormBody>

							<FormFooter>
								{submitError && <FormError error={submitError} />}

								<Button
									type="submit"
									primary
									large
									submitting={submitting}
									disabled={!values.nomeDestaque || !values.idAnexo?.uuid}
								>
									{editingDisabled ? 'Voltar' : 'Salvar'}
								</Button>
							</FormFooter>
						</FormWrapper>
					)
				}}
			</Form>

			{/* Inserção e edição da foto */}
			<Modal
				isOpen={modalOpen}
				onRequestClose={async () => {
					setModalOpen(false)
					await delay(500)
					setCameraOpen(false)
				}}
				renderHeader={() => 'Foto para o crachá'}
				// renderFooter={() => (<div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
				//   <Button to={HomePath} primary large>Começar</Button>
				// </div>)}
			>
				{!cameraOpen && !photoEditScreen ? (
					// tela inicial: instruções e opções de inserção
					<>
						<Text>
							<Title size="1.1em" style={{ marginTop: '1em' }}>
								Instruções
							</Title>
							<ul>
								<li>
									A foto deve ser tirada de frente contra um fundo branco e com
									iluminação uniforme;
								</li>
								<li>
									Não tirar a foto com óculos, chápeu ou outros objetos que
									dificultem a identificação;
								</li>
								<li>
									O rosto e os ombros da pessoa fotografada devem estar
									enquadrados e centralizados. Os olhos devem estar abertos,
									visíveis e direcionados para câmera;
								</li>
								<li>Selecione uma foto existente ou tire uma na hora.</li>
							</ul>
						</Text>

						<Button
							onClick={() => setCameraOpen(true)}
							large
							Icon={IconCamera}
							iconProps={{ size: 1.2 }}
						>
							Tirar foto
						</Button>
						<Button
							renderAs="label"
							large
							Icon={IconAttachment}
							iconProps={{ size: 1.5 }}
						>
							<HiddenInput
								type="file"
								onChange={(e) => setPhotoEditScreen(e.target?.files?.[0])}
							/>
							Selecionar foto existente
						</Button>
					</>
				) : cameraOpen ? (
					// tela de captura
					<>
						<Camera
							ref={cameraRef}
							style={{ marginBottom: 'var(--spacing)' }}
						/>
						<Button onClick={handlePhotoCapture} large Icon={IconCamera}>
							Capturar
						</Button>
					</>
				) : photoEditScreen ? (
					// tela de edição da foto (zoom & pan)
					<>
						{isSaving && (
							<PhotoSavingLoader>
								<Loading size={0.7} text="Salvando sua foto..." />
							</PhotoSavingLoader>
						)}

						<ImageEditor ref={editorRef} file={photoEditScreen} />

						<Button primary onClick={handlePhotoSave} large>
							Salvar foto
						</Button>
						<Button
							onClick={() => setCameraOpen(true)}
							large
							Icon={IconCamera}
							iconProps={{ size: 1.2 }}
						>
							Tirar outra foto
						</Button>
						<Button
							renderAs="label"
							large
							Icon={IconAttachment}
							iconProps={{ size: 1.5 }}
						>
							<HiddenInput
								type="file"
								onChange={(e) => setPhotoEditScreen(e.target?.files?.[0])}
							/>
							Selecionar outra foto
						</Button>
					</>
				) : undefined}
			</Modal>

			{/* Crachá */}
			<NameTagModal onRequestClose={closeNameTag} info={nameTagInfo} />
		</MainLayout>
	)
}

export default PhotoPage
