/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react'
import { Router, Switch, Redirect } from 'react-router-dom'
import ReactModal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import { debounce } from 'lodash'
import { history } from '~/helpers/history'

import pages from '~/pages'
import Page from '~/pages/_helpers/page'
import { loadUser } from '~/store/reducers/user/actions'
import { getAppReady } from '~/store/reducers/ui/selectors'
import { setViewPortSize } from '~/store/reducers/ui/actions'
import { Loading } from '~/components'
import { breakpoints } from './helpers/breakpoints'

import LoginPage, * as loginPageData from '~/pages/loginPage'
import RecoveryPage, * as recoveryPageData from '~/pages/recoveryPage'
import ResetPassPage, * as resetPassPageData from '~/pages/resetPassPage'
import LegalPage, * as legalPageData from '~/pages/legalPage'
import ScrollToTop from './helpers/scrollToTop'

ReactModal.setAppElement('#root')
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(255, 255, 255, 0.5)'

function App({ isPublic }) {
	const dispatch = useDispatch()
	const appReady = useSelector(getAppReady)

	// history.listen((location, action) => {
	// 	// console.log(action, location.pathname, location.state);
	// })

	// iniciando usuário
	useEffect(() => {
		if (!isPublic && !appReady) dispatch(loadUser())
	}, [dispatch, appReady, isPublic])

	// verificando tamanho de tela e setando no redux
	useEffect(() => {
		const handleWindowResize = () => {
			let viewPortSize

			for (const bp of Object.keys(breakpoints)) {
				if (window.matchMedia(`(min-width: ${breakpoints[bp]})`).matches) {
					viewPortSize = bp
					break
				}
			}
			dispatch(setViewPortSize(viewPortSize))
		}
		handleWindowResize()
		window.addEventListener('resize', debounce(handleWindowResize, 100))
	}, [dispatch])

	return !isPublic && !appReady ? (
		<Loading />
	) : (
		<Router history={history}>
			<ScrollToTop />{' '}
			{/* Resetar o scroll para o topo toda vez que a rota mudar */}
			{!isPublic && appReady ? (
				<Switch>
					{pages.map((page) => (
						<Page key={page.path} exact {...page} />
					))}
				</Switch>
			) : (
				<Switch>
					<Page {...loginPageData} exact component={LoginPage} />
					<Page {...recoveryPageData} exact component={RecoveryPage} />
					<Page {...resetPassPageData} exact component={ResetPassPage} />
					<Page {...legalPageData} exact component={LegalPage} />
					<Page path="*">
						<Redirect to={loginPageData.path} />
					</Page>
				</Switch>
			)}
		</Router>
	)
}

export default App
